import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';

import PropTypes from 'prop-types';
import ThumbnailContent from 'templates/ProductPage/MediaGallery/ThumbnailContent';
import media from '@activebrands/core-web/config/media';
import useSlider from '@activebrands/core-web/libs/slider/useSlider';
import fm from 'format-message';

const Wrapper = styled('div', {
    position: 'absolute',
    bottom: '12px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1,
    display: 'flex',
});

const SwipeIndicator = styled('div', ({ $isActive }) => ({
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    margin: '0 4px',
    border: 'none',
    transitionDuration: 'var(--duration-medium)',
    transitionTimingFunction: 'var(--ease)',
    backgroundColor: $isActive ? 'var(--color-bg-swipe-indicator-active)' : 'var(--color-bg-swipe-indicator)',

    [media.min['desktop.sm']]: {
        display: 'none',
    },
}));

const mediaThumbnail = ($isFirstActive, $isSecondActive, $lastChild) => {
    const activeBorder = '1px solid var(--color-border-thumbnail-active)';
    const inactiveBorder = '1px solid var(--color-border-thumbnail)';
    const isActive = $isFirstActive || $isSecondActive;

    return {
        display: 'none',

        [media.min['desktop.sm']]: {
            display: 'block',
            height: '60px',
            overflow: 'hidden',
            boxSizing: 'border-box',
            transitionDuration: 'var(--duration-medium)',
            transitionTimingFunction: 'var(--ease)',
            marginRight: $isFirstActive ? '-1px' : '4px',
            marginLeft: $isSecondActive ? '-1px' : '4px',
            borderRadius: $isFirstActive && !$lastChild ? '8px 0 0 8px' : $isSecondActive ? '0 8px 8px 0' : '8px',
            border: inactiveBorder,
            borderLeft: $isFirstActive ? activeBorder : inactiveBorder,
            borderRight: $isSecondActive || ($isFirstActive && $lastChild) ? activeBorder : inactiveBorder,
            borderTop: isActive ? activeBorder : inactiveBorder,
            borderBottom: isActive ? activeBorder : inactiveBorder,
        },
    };
};

const zoomThumbnail = isFirstActive => {
    const activeBorder = '1px solid var(--color-border-thumbnail-active)';
    const inactiveBorder = '1px solid var(--color-border-thumbnail)';
    return {
        width: '64px',
        overflow: 'hidden',
        boxSizing: 'border-box',
        border: isFirstActive ? activeBorder : inactiveBorder,
        borderRadius: '8px',
        margin: '4px 0px',
    };
};

const Thumbnails = ({ $style = {}, items = [], slider = {}, isZoomGallery = false }) => {
    const [css] = useStyletron();
    const { index } = useSlider(slider, ['index', 'isInfinite', 'numberOfSlides', 'slidesPerView']);

    if (items.length < 2) {
        return null;
    }

    return (
        <Wrapper style={{ ...$style }}>
            {items.map(({ url, type, typeValue }, i) => {
                const isTypeValueProduct = typeValue?.toLowerCase() === 'product';
                const isSecondActive = index + 1 === i;
                const isFirstActive = index === i;
                const isLastChild = i === items.length - 1;
                return (
                    <button
                        key={url}
                        type="button"
                        aria-label={fm(`Navigate to Thumbnail ${i}`)}
                        onClick={() => slider.slideTo(i, true)}
                    >
                        <SwipeIndicator $isActive={isFirstActive} />

                        <div
                            className={css({
                                ...(isZoomGallery
                                    ? zoomThumbnail(isFirstActive)
                                    : mediaThumbnail(isFirstActive, isSecondActive, isLastChild)),
                            })}
                        >
                            <ThumbnailContent
                                type={type}
                                url={url}
                                isTypeValueProduct={isTypeValueProduct}
                                loading="lazy"
                            />
                        </div>
                    </button>
                );
            })}
        </Wrapper>
    );
};

Thumbnails.propTypes = {
    $style: PropTypes.object,
    isZoomGallery: PropTypes.bool,
    items: PropTypes.array.isRequired,
    slider: PropTypes.object.isRequired,
};

export default Thumbnails;
